import React from 'react';
import Task from './task';
import { useGlobalContext } from '../context';
import { splai } from '../assets/agenti';

const ListaTaskuri = () => {
	const { locatii, authenticatedUser } = useGlobalContext();

	// const filteredLocatii = locatii
	// 	.filter(
	// 		(locatie) =>
	// 			locatie.Status === 'trebuie_depozitata' || locatie.Status === 'trebuie_eliberata'
	// 	)
	// 	.sort((a, b) => (a.Status < b.Status ? 1 : b.Status < a.Status ? -1 : 0))

	const treDepozitata = locatii
		.filter((locatie) => locatie.Status === 'trebuie_depozitata')
		.filter((locatie) => {
			const dataPreDepozitare = new Date(
				new Date().setTime(
					new Date(locatie.DataIntrare).getTime() - 7 * 24 * 60 * 60 * 1000
				)
			);
			return dataPreDepozitare < new Date();
		})
		.sort((a, b) =>
			a.DataIntrare > b.DataIntrare ? 1 : b.DataIntrare > a.DataIntrare ? -1 : 0
		);

	const treEliberata = locatii
		.filter((locatie) => locatie.Status === 'trebuie_eliberata')
		.filter((locatie) => {
			const dataPreEliberare = new Date(
				new Date().setTime(
					new Date(locatie.DataProgramareIesire).getTime() - 7 * 24 * 60 * 60 * 1000
				)
			);
			return dataPreEliberare < new Date();
		})
		.sort((a, b) =>
			a.DataProgramareIesire > b.DataProgramareIesire
				? 1
				: b.DataProgramareIesire > a.DataProgramareIesire
				? -1
				: 0
		);

	return (
		<section className='container-taskuri mt-5 mx-auto'>
			{authenticatedUser.username.toLowerCase() === 'bety'
				? treEliberata
						.filter((loc) => splai.includes(loc.Agent))
						.map((locatie) => {
							return <Task key={locatie.Locatie} {...locatie} />;
						})
				: authenticatedUser.username.toLowerCase() === 'petrariu'
				? treEliberata
						.filter((loc) => !splai.includes(loc.Agent) || loc.Agent === 'OSIAC MIHAIL')
						.map((locatie) => {
							return <Task key={locatie.Locatie} {...locatie} />;
						})
				: treEliberata.map((locatie) => {
						return <Task key={locatie.Locatie} {...locatie} />;
				  })}

			{authenticatedUser.username.toLowerCase() === 'bety'
				? treDepozitata
						.filter((loc) => splai.includes(loc.Agent))
						.map((locatie) => {
							return <Task key={locatie.Locatie} {...locatie} />;
						})
				: authenticatedUser.username.toLowerCase() === 'petrariu'
				? treDepozitata
						.filter((loc) => !splai.includes(loc.Agent) || loc.Agent === 'OSIAC MIHAIL')
						.map((locatie) => {
							return <Task key={locatie.Locatie} {...locatie} />;
						})
				: treDepozitata.map((locatie) => {
						return <Task key={locatie.Locatie} {...locatie} />;
				  })}
		</section>
	);
};

export default ListaTaskuri;
